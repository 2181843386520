<template>
    <div >
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar flat dense class="tc-title">
            <router-link to="student-Current-academics-dynamic" class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>  
            <v-toolbar-title>Upload CV</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn @click="cv_template()" class="primary logi mx-2" fab small icon ><v-icon class="white--text">mdi mdi-account-file-text-outline</v-icon></v-btn> 
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="logi mx-2" fab small @click="dialog_add=true"><v-icon>mdi-plus</v-icon></v-btn>
            <router-link to="student-offerletterdynamic" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
        <a class="blink" href="https://www.youtube.com/watch?v=nXhk38Mc2xQ&t=13s" target="_blank"><span style="color:red;font-size:35px;" class="mdi mdi-message-video"></span></a>
        </v-toolbar>
        <!--<template>
            <v-toolbar flat dense color="white">
                
                 <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <span style="width: 20px"></span>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark class="mb-2" @click="dialog_add=true"><v-icon>mdi-plus</v-icon> Extra Curricular</v-btn>
                </template>
            </v-toolbar>
        </template>
        <template>
            <v-data-table :headers="headers" :items="learner_Extra_Curricular_list" class="elevation-1" :search="search">
                <template v-slot:item.docUrl="{ item }">
                    <span v-if="item.docUrl=='NA'">
                   {{ item.docUrl }}
                    </span>
                    <span v-else>
                       <a v-bind:href="item.docUrl" target="blank" class="alink"><v-icon color="success">mdi-cloud-download</v-icon></a>
                    </span>
                </template>
                
                <template v-slot:item.edit="{ item }">
                    <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                </template>
                <template v-slot:item.delete="{ item }">
                   <v-icon color="red" @click="del(item)">mdi-delete-circle</v-icon>
                </template>
            </v-data-table>
        </template>-->
        <v-row>
            <v-col cols="12" sm='4' v-for="(item,index) in learnerCV" :key="index">
                <v-card class="mx-auto cardnew" max-width="400">
                    <v-card-title max-width="400" color="#1F7087" theme="dark">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <span v-on="on" style="color: white;">{{ item.cvtype }}</span>
                    </template>
                    <span>{{ item.cvtype}}</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text style="background-color: white;">
                   <div>
                    <div class="d-flex flex-no-wrap justify-space-between">
                       <div>
                        
                <v-card-subtitle style="color: black;"><b>CV Name: </b> <a style="color: cornflowerblue;" v-bind:href="item.fileurl" target="blank" class="alink pl-2">{{item.filename}}</a></v-card-subtitle>
                <v-card-subtitle style="color: black; padding-top: 0;"><b>CV Type: </b> <span class="pl-4">{{ item.cvtype }}</span></v-card-subtitle>
                <v-card-subtitle style="color: black; padding-top: 0;"><b>Is Default: </b> 
                <span v-if="item.isdefault==true" class="pl-1"><v-icon style="color: green;">mdi-checkbox-marked-circle</v-icon></span>
                <span v-if="item.isdefault==false" class="pl-1"><v-icon style="color: red;">mdi-close-circle-outline</v-icon></span>
                </v-card-subtitle>
                </div>
                </div>
                <v-card-actions>
                        <v-icon size="35px" color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                        <v-spacer></v-spacer> <v-spacer></v-spacer>
                        <v-icon size="35px" style="padding-left: 20%;" color="red" @click="del(item)">mdi-delete-circle</v-icon>
                </v-card-actions>
                </div>
            </v-card-text>
                </v-card>
                <!-- <v-card style=" margin: 8px; height:100%">
                    <v-card-title class="c-title">
                        {{item.cvtype}}
                    </v-card-title>
                    <v-card-text>
                        
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Filename</span>
                            </v-col>
                            
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : <a v-bind:href="item.fileurl" target="blank" class="alink">{{item.filename}}</a>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                                <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                                <v-spacer></v-spacer>
                                <v-icon color="red" @click="del(item)">mdi-delete-circle</v-icon>
                    </v-card-actions>
                </v-card> -->
            </v-col>
        </v-row>

        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_add" persistent max-width="700px">
                    <v-card>
                        <v-card-title class="card-title">
                            Add CV
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="6" sm="6" v-if="CV_Name_Dynamic.isActive && CV_Name_Dynamic">
                                        <span v-if="CV_Name_Dynamic.isRequired">
                                            <v-text-field v-model="savedata.cvname" item-text="name" item-value="id" outlined  :rules="[ruless, rules.isEmpty]">
                                                <template #label>
                                                    <span class="ml-1 mb-2">{{ CV_Name_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                                </template>
                                            </v-text-field>
                                        </span>
                                        <span v-else>
                                            <v-text-field v-model="savedata.cvname" item-text="name" item-value="id" outlined>
                                                <template #label>
                                                    <span class="ml-1 mb-2">{{ CV_Name_Dynamic.display_name }}</span>
                                                </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>

                                    <v-col cols="6" sm="6" v-if="CV_Type_Dynamic.isActive && CV_Type_Dynamic">
                                        <span v-if="CV_Type_Dynamic.isRequired">
                                           
                                            <v-select :items="cvTypeList" v-model="savedata.cvtype" item-text="type" outlined item-value="id"  :rules="[ruless, rules.isEmpty]">
                                                <template #label>
                                                    <span class="ml-1 mb-2">{{ CV_Type_Dynamic.display_name }}<small style="color: red"> *</small> </span>
                                                </template>
                                            </v-select>
                                        </span>
                                        <span v-else>
                                            <v-select :items="cvTypeList" v-model="savedata.cvtype" item-text="type" outlined item-value="id">
                                                <template #label>
                                                    <span class="ml-1 mb-2">{{ CV_Type_Dynamic.display_name }} </span>
                                                </template>
                                            </v-select>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
            
                                    <v-col cols="6" sm="6"  v-if="CV_File_Dynamic.isActive && CV_File_Dynamic">
                                        <span v-if="CV_File_Dynamic.isRequired">
                                            <v-file-input type="file" label="CV File" placeholder="Select CV file" persistent-hint 
                                            :hint="editedIndex != -1 ? 'File Size Should be less than 250 kb,JPEG, JPG & PDF' : 'File Size Should be less than 250 kb,JPEG, JPG & PDF'"
                                            accept=".pdf, .jpg, .jpeg" prepend-icon="" :prepend-inner-icon="fileIcon" outlined ref="file" :rules="[ruless, rules.isEmpty]" show-size v-model="savedata.file" 
                                            clearable :error-messages="error.savedata.file ? 'Please Select CV' : ''" height="6">
                                            <template #label>
                                                {{CV_File_Dynamic.display_name}}<small style="color: red"> *</small>
                                            </template>
                                            </v-file-input>
                                        </span>
                                        <span v-else>
                                            <v-file-input type="file" label="CV File" placeholder="Select CV file" persistent-hint 
                                            :hint="editedIndex != -1 ? 'File Size Should be less than 250 kb,JPEG, JPG & PDF' : 'File Size Should be less than 250 kb,JPEG, JPG & PDF'"
                                            accept=".pdf, .jpg, .jpeg" prepend-icon="" :prepend-inner-icon="fileIcon" outlined ref="file" show-size v-model="savedata.file" 
                                            clearable :error-messages="error.savedata.file ? 'Please Select CV' : ''" height="6">
                                            <template #label>
                                                {{CV_File_Dynamic.display_name}}
                                            </template>
                                            </v-file-input>
                                        </span>
                                    </v-col>
                                      <v-col cols="6" sm="6" v-if="Is_Default_Dynamic && Is_Default_Dynamic.isActive">
                                        <v-checkbox label="Is Default?" v-model="savedata.isdefault"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_add= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Save</v-btn> -->
                             <v-btn color="primary darken-1"  outlined text @click="dialog_add= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_edit" persistent max-width="700px">
                    <v-card>
                        <v-card-title class="card-title">
                            Edit CV
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="6" sm="6" v-if="CV_Name_Dynamic.isActive && CV_Name_Dynamic">
                                        <span v-if="CV_Name_Dynamic.isEditable">
                                            <span v-if="CV_Name_Dynamic.isRequired">
                                                <v-text-field type="text" label="CV Name" outlined placeholder="Enter CV Name " required="true" clearable v-model="editedItem.cvname"
                                                :error-messages="error.editedItem.cvname ? 'Please Enter CV Name' : ''">
                                                <template #label>
                                                    {{ CV_Name_Dynamic.display_name }}<small style="color: red"> *</small>
                                                </template>
                                                </v-text-field>
                                            </span>
                                            <span v-else>
                                                <v-text-field type="text" label="CV Name" outlined placeholder="Enter CV Name " clearable v-model="editedItem.cvname">
                                                <template #label>
                                                    {{ CV_Name_Dynamic.display_name }}
                                                </template>
                                                </v-text-field>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-text-field disabled type="text" label="CV Name" outlined placeholder="Enter CV Name " v-model="editedItem.cvname">
                                            <template #label>
                                                {{ CV_Name_Dynamic.display_name }}
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col cols="6" sm="6"  v-if="CV_Type_Dynamic.isActive && CV_Type_Dynamic">
                                        <span v-if="CV_Type_Dynamic.isEditable">
                                            <span v-if="CV_Type_Dynamic.isRequired">
                                                <v-select :items="cvTypeList"  label="CV Type" value="cytype"  item-text="type" outlined item-value="id" placeholder="Please Select CV Type"  required="true" clearable v-model="editedItem.cvtype"
                                                :error-messages="error.editedItem.cvtype ? 'Please Select CV Type' : ''">
                                                <template #label>
                                                    {{ CV_Type_Dynamic.display_name }}<small style="color: red"> *</small>
                                                </template>
                                                </v-select>
                                            </span>
                                            <span v-else>
                                                <v-select :items="cvTypeList"  label="CV Type" value="cytype"  item-text="type" outlined item-value="id" placeholder="Please Select CV Type" clearable v-model="editedItem.cvtype">
                                                <template #label>
                                                    {{ CV_Type_Dynamic.display_name }}
                                                </template>
                                                </v-select>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-select :items="cvTypeList" disabled label="CV Type" value="cytype"  item-text="type" outlined item-value="id" placeholder="Please Select CV Type" v-model="editedItem.cvtype">
                                            <template #label>
                                                {{ CV_Type_Dynamic.display_name }}
                                            </template>
                                            </v-select>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" sm="6"  v-if="CV_File_Dynamic.isActive && CV_File_Dynamic">
                                        <span v-if="CV_File_Dynamic.isEditable">
                                            <span v-if="CV_File_Dynamic.isRequired">
                                                <v-file-input type="file" label="CV File" outlined placeholder="Select CV file" persistent-hint 
                                                :hint="editedIndex != -1 ? 'File Size Should be less than 250 kb,JPEG, JPG & PDF' : 'File Size Should be less than 250 kb,JPEG, JPG & PDF'"
                                                accept=".pdf, .jpg, .jpeg" prepend-icon="" :prepend-inner-icon="fileIcon" required="true"
                                                clearable v-model="editedItem.file" :error-messages="error.editedItem.file ? 'Please Select CV' : ''"
                                                ><template #label>
                                                    {{ CV_File_Dynamic.display_name }}<small style="color: red"> *</small>
                                                </template>
                                                </v-file-input>
                                                <span>Filename : &nbsp;</span>{{ file_name }}<br>
                                            </span>
                                            <span v-else>
                                                <v-file-input type="file" label="CV File" outlined placeholder="Select CV file" persistent-hint 
                                                :hint="editedIndex != -1 ? 'File Size Should be less than 250 kb,JPEG, JPG & PDF' : 'File Size Should be less than 250 kb,JPEG, JPG & PDF'"
                                                accept=".pdf, .jpg, .jpeg" prepend-icon="" :prepend-inner-icon="fileIcon"
                                                clearable v-model="editedItem.file">
                                                <template #label>
                                                    {{ CV_File_Dynamic.display_name }}
                                                </template>
                                                </v-file-input>
                                                <span>Filename : &nbsp;</span>{{ file_name }}<br>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-file-input disabled type="text" outlined persistent-hint 
                                                accept=".pdf, .jpg, .jpeg" prepend-icon="" :prepend-inner-icon="fileIcon"
                                                clearable v-model="editedItem.file">
                                                <template #label>
                                                    {{ CV_File_Dynamic.display_name }}
                                                </template>
                                                </v-file-input>
                                                <span>Filename : &nbsp;</span>{{ file_name }}<br>
                                        </span>
                                    </v-col>
                                    <v-col cols="6" sm="6" v-if="Is_Default_Dynamic.isActive">
                                        <v-checkbox label="Is Default?" v-model="editedItem.isdefault"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                           
                             <v-btn color="primary darken-1"  outlined text @click="dialog_edit= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="edit()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog_upload" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Please wait File uploding...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_delete" persistent max-width="290">
                    <v-card>
                        <v-card-title class="del-card-title">CV Delete</v-card-title>
                        <input type="hidden" v-model="deleteItem.id" />
                        <v-card-text>
                            Are you sure you want Delete this CV file?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog_delete = false">No</v-btn>
                            <v-btn color="red darken-1" text @click="deletedata()">Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <!-- <v-dialog v-model="cv_template_dialog">
            <v-row justify="center">
                <v-col cols="12" sm="3" v-for="(item, index) in filteredSortSearchList" :key="index">
                <v-card id="cardhovernew">
                    <center>
                        <v-avatar class="avatariconnew mt-8" size="70">
                        <v-avatar size="45">
                        <v-icon size="30" class="btntitlenew">{{item.firstName}}</v-icon>
                        </v-avatar>
                        </v-avatar>
                        <br/><br/>
                        <h3>{{item.name}}</h3>
                        <br/>
                        <a :href="item.file_url" target="_blank"><v-icon color="primary" size="40" :title="'Click here to download template'" class="mb-5">mdi-download-circle</v-icon></a>
                    </center>
                </v-card>
            </v-col>
            </v-row>
        </v-dialog> -->
        <v-dialog v-model="cv_template_dialog" max-width="700">
                <v-card>
                    <v-card-title class="cardnew1">CV Template</v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-text>
                        <v-row>
                        <v-col align="center">
                        <v-text-field class="centered-input"
                                v-model="search"
                                append-icon="search"
                                label="Search Template..."
                                single-line
                                hide-details
                                style="width: 40%"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                       <v-row>
                        <v-col cols="12" sm="4" v-for="(item, index) in filteredSortSearchList" :key="index">
                            <v-card :elevation="5" id="cardhovernew" fill-height style=" margin: 8px; height:100%">
                                <br />
                                <center>
                                <v-avatar class="avatariconnew" size="70">
                                <v-avatar size="45">
                                <v-icon size="30" class="btntitlenew">{{item.firstName}}</v-icon>
                                </v-avatar>
                                </v-avatar>
                                <br/><br/>
                                <h3>{{item.name}}</h3>
                                <br/>
                                <a :href="item.file_url" target="_blank"><v-icon color="primary" size="40" :title="'Click here to download template'">mdi-download-circle</v-icon></a>
                                </center>
                            </v-card>
                        </v-col>
                       </v-row>
                    </v-card-text>
                    <v-card-actions>
                         <span style="margin-left: 45%;">
                        <v-btn class="primary" color="white darken-1" outlined text @click="cv_template_dialog=false;">Cancel</v-btn>
                         </span>
                    </v-card-actions>
                </v-card>
        </v-dialog> 
    </div>
</template>

<script>
    import axios from "axios";
    import StudentProfile from "./StudentProfile";
    export default {
        components: {
            StudentProfile,
        },
        data: () => ({
            ruless: [
        (value) => {
          if (!value) {
            return [];
          } else if (/^\d+$/.test(value)) {
            return "Numeric values are not allowed";
          } else {
            return true;
          }
        },
      ],
      rules: {
        isEmpty: (value) => !!value || 'Field is required',
      },
            dialog_upload: false,
            learnerCV:"",
            savedata: {
                file: null,
                cvtype: null,
                cvname:"",
                isdefault:false,
            },
            cvTypeList: "",
            dialog_add: false,
            dialog_edit: false,
            file_name: "",
            fileIcon: 'mdi-file',
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            editFile:'',
            overlay:false,
            dialog: false,
            dialog_attach:false,
            
            error: {
            savedata: {
                file: false,
                cvname: "",
            },
            editedItem:{
                file: false,
                cvname: "",
            }
        },
            editedItem: {
               file:null,
               cvname:"",
               cvtype:null,
               isdefault:false,
               id:"",
            },
            deleteItem: {
                id: "",
            },
            avtivityType: {
                id: "",
                activitytype: "",
            },
            dialog_delete: false,
            cv_template_dialog: false,
            CVTemplateList:[],
            CV_Name_Dynamic: "",
            CV_Type_Dynamic: "",
            CV_File_Dynamic: "",
            Is_Default_Dynamic: "",
            editedIndex: -1,
        }),

        watch: {
            dialog(val) {
                val || this.close();
            },
            overlay (val) {
        val= () => {
          this.overlay = false
        };
      },
        },
        mounted() {
            this.onLoad();
        },
        computed: {
    
        filteredSortSearchList(){
            function compare(a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            }
            if (!this.search) return this.CVTemplateList.sort(compare);
            
            return this.CVTemplateList.filter(tbdata=>{
                return tbdata.name.toLowerCase().indexOf(this.search.toLowerCase())>=0       
            })
            }
        }, // end of computed()
        methods: {
            onLoad() {
                this.overlay=true;
                axios
                    .post("/LearnerData/learnerCV")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            if(this.load = true){
                                this.overlay = false;
                                this.cvTypeList = res.data.cvTypeList;
                            this.learnerCV=res.data.learnerCV;
                            this.CV_Name_Dynamic = res.data.CV_Name;
                            this.CV_Type_Dynamic = res.data.CV_Type;
                            this.CV_File_Dynamic = res.data.CV_File;
                            this.Is_Default_Dynamic = res.data.Is_Default;
                            }
                          // window.alert(JSON.stringify(this.learnerCV))
                    
                        } else {
                            this.showSnackbar("#b71c1c", "No Data");
                            //window.console.log(res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        //window.console.log(error);
                    })
                    .finally(() => {
                        // 'use strict';
                        var overlay=false;
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                this.dialog_edit = true;
                this.editedItem.id = item.id;
                this.editedItem.isdefault = item.isdefault;
                this.file_name=item.filename;
                this.editedItem.cvname= item.resumename;
                this.editedItem.cvtype= item.cvtype_id;
               
            },
            del(item) {
                this.dialog_delete = true;
                this.deleteItem.id = item.id;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            
            save() {

                if(this.CV_Name_Dynamic.isRequired){
                    if (!this.savedata.cvname) {
                        this.showSnackbar("#b71c1c", "Please Enter CV Name...");
                        this.error.savedata.cvname = true;
                        return;
                    }
                }

                if(this.CV_Type_Dynamic.isRequired){
                    if (!this.savedata.cvtype) {
                        this.showSnackbar("#b71c1c", "Select CV Type...");
                        return;
                    }
                }
               
                if(this.CV_File_Dynamic.isRequired){
                    if(!this.savedata.file){
                        this.showSnackbar("#b71c1c", "Please Select A File...");
                        this.error.savedata.file = true;
                        return;
                    }
                }
                
                // if(this.savedata.file != null){
                //     console.log(this.savedata.file.name);
                //         var file = this.savedata.file.name;
                //         console.log(file);
                //          var ext = file.split(".");
                //             ext = ext[ext.length - 1].toLowerCase();
                //             var arrayExtensions = ["pdf"];

                //             if (arrayExtensions.lastIndexOf(ext) == -1) {
                //                 // alert("Invalid file.. Only pdf can be uploaded");
                //                 this.showSnackbar("#b71c1c", "Please Select A only PDF File...");
                //                 $("#file").val("");
                //             }
                //         }
                
                    //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
                    if(this.savedata.file){
                        var file = this.savedata.file.name;
                         var ext = file.split(".");
                            ext = ext[ext.length - 1].toLowerCase();
                            var arrayExtensions = ["pdf","jpeg","jpg"];

                            if (arrayExtensions.lastIndexOf(ext) == -1) {
                                // alert("Invalid file.. Only pdf can be uploaded");
                                this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & JPG File...");
                            return
                            }
                    
                    //for only allow upload cv when size is 250kb
                    const upload = this.savedata.file;
                    const maxSize = 250 * 1024;
                    if(upload.size > maxSize){
                        this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                        return
                    }
                }

                    let formData = new FormData();
                    formData.append("cvname", this.savedata.cvname);
                    formData.append("cvtype", this.savedata.cvtype);
                    formData.append("file", this.savedata.file);
                    formData.append("isdefault", this.savedata.isdefault);
                    this.dialog_add = false;
                    this.dialog_upload = true;
                    axios
                        .post("/LearnerData/saveCV", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.dialog_add = false;
                                this.dialog_upload = false;
                                this.showSnackbar("#4caf50", "CV Addded Successfully..."); // show snackbar on success
                                this.onLoad();
                                this.savedata.cvtype = false;
                                this.savedata.cvname = null;
                                this.savedata.file = null;
                                this.savedata.isdefault= false;
                            } else {
                                this.dialog_upload = false;
                                this.showSnackbar("#b71c1c", "CV Already Present!!!");
                            }
                        })
                        .catch((error) => {
                            //window.console.log(error);
                        });       
                    this.close();
 //               }
                
            }, // end of Save()
            edit() {

                if(this.CV_Name_Dynamic.isRequired){
                    if(!this.editedItem.cvname){
                        this.showSnackbar("#b71c1c", "Please Enter A CV Name...");
                        this.error.editedItem.cvname = true;
                        return
                    }
                }

                if(this.CV_Type_Dynamic.isRequired){
                    if(!this.editedItem.cvtype){
                        this.showSnackbar("#b71c1c", "Please Select A CV Type...");
                        this.error.editedItem.cvtype = true;
                        return
                    }
                }

                if(this.CV_File_Dynamic.isRequired){
                    if(!this.editedItem.file){
                        this.showSnackbar("#b71c1c", "Please Select A File...");
                        this.error.editedItem.file = true;
                        return
                    }
                }
               

                    //for only allow extensions while upload (e.g. .pdf  .jpeg  .png)
                    if(this.editedItem.file){
                    console.log(this.editedItem.file.name);
                        var file = this.editedItem.file.name;
                        console.log(file);
                         var ext = file.split(".");
                            ext = ext[ext.length - 1].toLowerCase();
                            var arrayExtensions = ["pdf","jpeg","jpg"];

                            if (arrayExtensions.lastIndexOf(ext) == -1) {
                                // alert("Invalid file.. Only pdf can be uploaded");
                                this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & JPG File...");
                            return
                            }


                    //for only allow upload cv when size is 250kb
                    const upload = this.editedItem.file;
                    const maxSize = 250 * 1024;
                    if(upload.size > maxSize){
                        this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                        return
                    }
                }
                
                let formData = new FormData();
                formData.append("id", this.editedItem.id);
                formData.append("file", this.editedItem.file);
                formData.append("cvname", this.editedItem.cvname);
                formData.append("cvtype", this.editedItem.cvtype);
                formData.append("isdefault", this.editedItem.isdefault);
                this.dialog_edit = false;
               
              // alert(this.editedItem.isdefault)
              
                  this.dialog_upload = true;
                 axios
                    .post("/LearnerData/editCV", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.dialog_edit = false;
                            this.dialog_upload = false;
                            this.showSnackbar("#4caf50", "CV Updated Successfully..."); // show snackbar on success
                            this.onLoad();
                        } else {
                            this.dialog_upload = false;
                            this.showSnackbar("#b71c1c", "CV Already Present!!!");
                        }
                    })
                    .catch((error) => {
                        //window.console.log(error);
                    });
                this.close();
            
               
            }, // end of edit()
            deletedata() {
                //alert("Are you sure Delete....")
                //this.deleteItem.lactivity_id=item.id
                this.dialog_delete = false;
                axios.post("/LearnerData/deleteCV", this.deleteItem).then((res) => {
                    if (res.data.code == "200") {
                        if ((this.load = true)) {
                            this.onLoad();
                            this.showSnackbar("#4caf50", " CV File Deleted Successfully...");
                            
                        }
                    }else if (res.data.code == "cannotdelete")  {
                        this.showSnackbar("#b71c1c", "CV File Can not be deleted because it is being referenced...");
                    } else {
                        this.showSnackbar("#b71c1c", "CV File Not Deleted...");
                    }
                });
            },

            cv_template() {
                this.cv_template_dialog=true;
                axios
                .post("/LearnerData/getLearnerCVTemplate")
                .then((res) => {
                    if (res.data.msg == "200") {
                            this.CVTemplateList = res.data.CVTemplateList;
                    } else {
                        this.showSnackbar("#b71c1c", "No Data Found");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); 
                })
                .finally(() => {

                });
            },
        },
    };
</script>
<style scoped>
.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }

    .ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .logi{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .alink {
        text-decoration: none;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
     .required{
    color:red;
    font-size:14px;
    font-style:bold;
    }
    .blink {
            animation: blinker 0.77s linear infinite;
            color: red;
            font-family: sans-serif;
        }
        @keyframes blinker {
            50% {
                opacity: 0;
            }
        }
        .cardnew{
            background-image: linear-gradient(-90deg, #8edffd, #034c70);
            color: #FFF;
        }
        .cardnew:hover{
            box-shadow: 5px 5px lightgrey;
            border-radius: 10px 5px;
            background-image: linear-gradient(-90deg, #8edffd, #034c70);
            color: #FFF;
        }
        .cardnew1{
            background-image: linear-gradient(-90deg, #8edffd, #034c70);
            color: #FFF;
        }

        .avatariconnew {
            border: 1px solid #0000001a;
        }
        .linkiconnew {
        color: #bdbdbd;
        }

        #cardhovernew {
        transition: cubic-bezier(0.895, 0.03, 0.685, 0.22);
        }
        #cardhovernew:hover {
        background: #ededed;
        }

        #cardhovernew:hover .avatariconnew {
        border: 1px solid #3f51b5;
        background: white;
        }
        .btntitlenew{
        text-transform: uppercase;
        color: white;
        background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
        font-size: x-large;
        font-weight: bolder;
        }
</style>
